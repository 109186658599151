<template>
  <div class="Home">
    <van-popup
      v-model="show"
      position="right"
      :style="{ height: '100%', width: '223px' }"
      class="genduomenu"
    >
      <el-input
        suffix-icon="el-icon-search"
        class="PeSearch"
        v-model="searchText"
      >
      </el-input>
      <ul class="peMenu">
        <li @click="toHome">
          <span>首页</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toBusiness">
          <span>经营范围</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toNews">
          <span>新闻资讯</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toAdvantage">
          <span>核心优势</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toGroup_Introduction">
          <span>集团介绍</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toContact_us">
          <span>联系我们</span><i class="el-icon-arrow-right"></i>
        </li>
      </ul>
    </van-popup>
    <div id="header">
      <div class="top">
        <img src="@/assets/Home/login.png" alt="" class="logo" />
        <ul class="left">
          <li class="first">
            <img src="@/assets/Home/homeIcon.png" class="homeIcon" alt="" />
            {{ $t('home.HomePage') }}
          </li>
          <li @click="toBusiness">{{ $t('home.businessScope') }}</li>
          <li @click="toNews">{{ $t('home.News') }}</li>
          <li @click="toAdvantages">{{ $t('home.Advantage') }}</li>
          <li @click="toGroup_Introductions">{{ $t('home.Introduce') }}</li>
          <li @click="toContact_us">{{ $t('home.ContactUs') }}</li>
        </ul>
        <div class="right">
          <el-input suffix-icon="el-icon-search" v-model="searchText">
          </el-input>
          <Lang></Lang>
        </div>
        <img
          class="rightPe"
          @click.stop="show = true"
          src="../../assets/PE/Home/436.png"
          alt=""
        />
      </div>
      <img id="left_arrow" src="../../assets/Home/1.png" @click="prev" />
      <img id="right_arrow" src="../../assets/Home/1.png" @click="next" />
      <ul class="currentBox">
        <li
          :class="index + 1 == current ? 'active' : ''"
          v-for="(item, index) in 4"
          @click="clickChange(index)"
          :key="index"
        ></li>
      </ul>
    </div>
    <div class="containerSS" @click.stop="popHide">
      <div class="layer1">
        <h3 class="title">{{ $t('home.businessScope') }}</h3>
        <p class="introduce">{{ $t('home.layer1Introduce') }}</p>
        <ul class="content">
          <li class="top" @click="toNewEnergy">
            <div class="slogan">{{ $t('home.NewEnergy') }}</div>
          </li>
          <li @click="toCar">
            <div class="slogan">{{ $t('home.Car') }}</div>
          </li>
          <li @click="toIndustrial">
            <div class="slogan">{{ $t('home.Industry') }}</div>
          </li>
          <li @click="toCommunication">
            <div class="slogan">{{ $t('home.Communication') }}</div>
          </li>
        </ul>
        <div class="move" @click="toBusiness">{{ $t('home.More') }}</div>
      </div>
      <div class="layer2">
        <div class="titles">
          <h2>
            {{ $t('home.Advantage') }}<span>/</span>
            <span>{{ $t('home.CoerAdvantage') }}</span>
          </h2>
        </div>
        <ul class="top">
          <li>
            <p class="num">100+</p>
            <p class="introduce">{{ $t('home.CumulativeCustomers') }}</p>
          </li>
          <li>
            <p class="num">50+</p>
            <p class="introduce">{{ $t('home.SuccessfulSolutions') }}</p>
          </li>
          <li>
            <p class="num">10+</p>
            <p class="introduce">{{ $t('home.RDexperience') }}</p>
          </li>
        </ul>
        <el-divider>
          <img src="../../assets/Home/多边形 1.png" alt="" />
        </el-divider>
        <ul class="bottom">
          <li>
            <div class="imgBox">
              <img src="../../assets/Home/411.png" alt="" />
            </div>
            <div class="imgBoxPe">
              <img src="../../assets/PE/Home/路径 167.png" alt="" />
            </div>
            <p class="title">{{ $t('home.SchemeIsReasonable') }}</p>
            <p class="introduce">
              <span>{{ $t('home.Major') }}</span
              >{{ $t('home.ServiceTeam') }}
            </p>
          </li>
          <li>
            <div class="imgBox">
              <img src="../../assets/Home/路径 167.png" alt="" />
            </div>
            <div class="imgBoxPe">
              <img src="../../assets/PE/Home/412.png" alt="" />
            </div>
            <p class="title">{{ $t('home.ReliableQuality') }}</p>
            <p class="introduce">
              {{ $t('home.ProfessionalApplication')
              }}<span>{{ $t('home.Major') }}</span
              >{{ $t('home.ProductDetails') }}
            </p>
          </li>
          <li>
            <div class="imgBox">
              <img src="../../assets/Home/412.png" alt="" />
            </div>
            <div class="imgBoxPe">
              <img src="../../assets/PE/Home/411.png" alt="" />
            </div>
            <p class="title">{{ $t('home.OnTimeDelivery') }}</p>
            <p class="introduce">
              {{ $t('home.TenYears') }}<span>{{ $t('home.Accurate') }}</span
              >{{ $t('home.ControlTheTime') }}
            </p>
          </li>
          <li>
            <div class="imgBox">
              <img src="../../assets/Home/413.png" alt="" />
            </div>
            <div class="imgBoxPe">
              <img src="../../assets/PE/Home/413.png" alt="" />
            </div>
            <p class="title">{{ $t('home.ConsiderateService') }}</p>
            <p class="introduce">
              {{ $t('home.Use') }}<span>{{ $t('home.Service') }}</span
              >{{ $t('home.ExperienceOfLarge') }}
            </p>
          </li>
        </ul>
      </div>
      <div class="layer3" @click="toGroup_Introductions">
        <h3 class="titles">{{ $t('home.Introduce') }}</h3>
        <h2 class="introduce">{{ $t('home.DreamOfTheWorld') }}</h2>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from '../../components/footer.vue'
import Lang from '../../components/Lang.vue'

export default {
  components: {
    footers,
    Lang
  },
  data () {
    return {
      searchText: '',
      current: 1,
      show: false
    }
  },
  created () {
    this.$store.commit('setShow', false)
  },
  methods: {
    toHome () {
      this.$router.push('/Home')
    },
    toAdvantage () {
      this.$router.push('/Advantage')
    },
    toGroup_Introduction () {
      this.$router.push('/Group_Introduction')
    },
    //下一个banner
    next () {
      if (this.current < 4) {
        this.current++
        document.getElementById('header').style.backgroundImage =
          'url(' +
          require('../../assets/Home/banner' + this.current + '.png') +
          ')'
      } else {
        this.current = 1
        document.getElementById('header').style.backgroundImage =
          'url(' +
          require('../../assets/Home/banner' + this.current + '.png') +
          ')'
      }
    },
    //上一个banner
    prev () {
      if (this.current > 1) {
        this.current--
        document.getElementById('header').style.backgroundImage =
          'url(' +
          require('../../assets/Home/banner' + this.current + '.png') +
          ')'
        console.log(this.current)
      } else {
        this.current = 4
        document.getElementById('header').style.backgroundImage =
          'url(' +
          require('../../assets/Home/banner' + this.current + '.png') +
          ')'
      }
    },
    //点击currentBox内的li改变背景
    clickChange (index) {
      this.current = index + 1
      document.getElementById('header').style.backgroundImage =
        'url(' +
        require('../../assets/Home/banner' + this.current + '.png') +
        ')'
    },
    toBusiness () {
      this.$router.push('/Business')
    },
    toNewEnergy () {
      this.$router.push('/NewEnergy')
    },
    toCar () {
      this.$router.push('/Car')
    },
    toIndustrial () {
      this.$router.push('/Industrial')
    },
    toCommunication () {
      this.$router.push('/Communication')
    },
    toNews () {
      this.$router.push('/News')
    },
    toAdvantages () {
      this.$router.push('/Advantage')
    },
    toContact_us () {
      this.$router.push('/Contact_us')
    },
    toGroup_Introductions () {
      this.$router.push('/Group_Introduction')
    },
    popHide () {
      this.show = false
    }
  }
}
</script>

<style lang="less" scoped>
.Home {
  width: 100%;
  #header {
    width: 100%;
    height: 960px;
    background: url('../../assets/Home/banner1.png') no-repeat;
    background-size: cover;
    position: relative;
    .top {
      width: 1400px;
      height: 80px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      .logo {
        width: 95px;
        height: 27px;
        margin-right: 30px;
      }
      .left {
        width: 1000px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          color: #fff;
          list-style: none;
          display: block;
          cursor: pointer;
          font-size: 16px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          .homeIcon {
            margin-right: 10px;
          }
        }
        .first {
          border-left: 1px solid #fff;
          padding-left: 21px;
          display: flex;
          align-items: center;
        }
      }
      .right {
        margin-left: 145px;
        width: 280px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /deep/ .el-input {
          width: 206px;
          height: 36px;
          .el-input__inner {
            background-color: rgba(255, 255, 255, 0.3);
            -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
            border-radius: 25px;
            border: 0;
            color: #fff;
          }
          .el-icon-search {
            color: #fff;
          }
        }
      }
      .rightPe {
        display: none;
      }
    }
    #left_arrow {
      position: absolute;
      top: 50%;
      left: 150px;
      margin-top: -60px;
    }
    #right_arrow {
      position: absolute;
      top: 50%;
      right: 150px;
      transform: rotate(-180deg);
      margin-top: -60px;
    }
    .currentBox {
      width: 200px;
      position: absolute;
      right: 150px;
      bottom: 46px;
      display: flex;
      margin-top: -16px;
      li {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #fff;
        margin-left: 20px;
        list-style: none;
      }
      .active {
        width: 40px;
        border-radius: 8px;
        background-color: #4c97fc;
      }
    }
  }
  .containerSS {
    width: 100% !important;
    padding: 0;
    .layer1 {
      width: 100% !important;
      padding: 0 0px;
      margin: 0 auto;
      .title {
        font-size: 40px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: center;
        color: #333333;
        margin-top: 10px;
      }
      .introduce {
        font-size: 16px;
        font-family: Didot, Didot-Regular;
        font-weight: 400;
        text-align: center;
        color: #999999;
      }
      .content {
        width: 1640px;
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 auto;
        .top {
          width: 1640px;
          height: 500px;
        }
        li {
          list-style: none;
          width: 525px;
          height: 500px;
          position: relative;
          .slogan {
            width: 254px;
            height: 66px;
            line-height: 66px;
            background: rgba(255, 255, 255, 0.3);
            border-bottom-left-radius: 5px;
            -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
            position: absolute;
            color: #fff;
            right: 0;
            text-align: center;
            font-size: 30px;
            font-family: Microsoft YaHei, Microsoft YaHei-Bold;
            font-weight: 700;
            color: #ffffff;
          }
          &:nth-child(1) {
            margin-bottom: 20px;
            background: url('../../assets/Home/xinnengy.png');
            background-size: cover;
          }
          &:nth-child(2) {
            background: url('../../assets/Home/car.png');
            background-size: cover;
          }
          &:nth-child(3) {
            background: url('../../assets/Home/gongkong.png');
            background-size: cover;
          }
          &:nth-child(4) {
            background: url('../../assets/Home/tongxun.png');
            background-size: cover;
          }
          &:nth-child(2),
          &:nth-child(3) {
            margin-right: 20px;
          }
        }
      }
      .move {
        width: 380px;
        height: 53px;
        line-height: 53px;
        background: #3c91e9;
        border-radius: 5px;
        box-shadow: 0px 3px 6px 0px rgba(60, 145, 233, 0.38);
        margin: 0 auto;
        margin-top: 60px;
        font-size: 24px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        margin-bottom: 40px;
      }
    }
    .layer2 {
      width: 100%;
      height: 795px;
      background: url('../../assets/Home/feiji.png');
      background-size: cover;
      padding: 80px 150px 110px;
      display: flex;
      flex-wrap: wrap;
      .titles {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 80px;
        line-height: 80px;
        h2 {
          display: block;
          width: 960px;
          font-size: 40px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: left;
          color: #333333;
          display: flex;
        }
        span {
          font-size: 16px;
          font-family: Didot, Didot-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          &:nth-child(1) {
            margin-left: 20px;
            margin-right: 20px;
          }
        }
      }
      .top {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 50px;
        li {
          height: 100%;
          list-style: none;
          .num {
            font-size: 76px;
            font-family: DIN, DIN-Regular;
            font-weight: 400;
            text-align: center;
            color: #3c91e9;
            line-height: 94px;
          }
          .introduce {
            font-size: 20px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: center;
            color: #333333;
            line-height: 43px;
          }
        }
      }
      .el-divider {
        margin-top: 60px;
        margin-bottom: 60px;
        .el-divider__text {
          background-color: rgba(255, 255, 255, 0);
        }
        img {
          margin: 0 12px;
        }
      }
      .bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
          width: 25%;
          list-style: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          .imgBox {
            margin: 0 auto;
            height: 85px;
            img {
              display: block;
              margin: 0 auto;
            }
          }
          .imgBoxPe{
            display: none;
          }

          .title {
            margin-top: 23px;
            width: 100%;
            display: block;
            font-size: 26px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
            text-align: center;
          }
          .introduce {
            width: 235px;
            margin: 0 auto;
            display: block;
            font-size: 16px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: center;
            color: #bcbfc2;
            line-height: 36px;
            text-align: center;
            span {
              color: #3c91e9;
            }
          }
        }
      }
    }
    .layer3 {
      height: 800px;
      margin-top: 90px;
      background: url('../../assets/Home/jituan.png');
      background-size: cover;
      padding-top: 290px;
      .titles {
        font-size: 40px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: left;
        color: #ffffff;
        height: 52px;
        line-height: 52px;
        text-align: center;
      }
      .introduce {
        font-size: 61px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: left;
        color: #ffffff;
        margin-top: 90px;
        text-align: center;
      }
    }
  }
  .footer {
    background: url('../../assets/Home/bottom.png');
    background-size: cover;
    height: 470px;
    margin-top: 90px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .top {
      width: 1400px;
      height: 40px;
      margin: 0 auto;
      padding-top: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        height: 41px;
      }
      .right {
        display: flex;
        justify-content: space-between;
        li {
          margin-left: 100px;
          display: flex;
          align-items: center;
        }
      }
    }
    .bottom {
      width: 1400px;
      height: 32px;
      margin: 0 auto;
      margin-top: 92px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .first {
        display: flex;
        align-items: center;
        .left {
          margin-right: 10px;
        }
      }
      .last {
        display: flex;
        align-items: center;
        * {
          margin-left: 30px;
        }
        span {
          font-size: 20px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .Home {
    width: 100%;
    position: relative;
    #header {
      width: 100%;
      height: 235px;
      background: url('../../assets/PE/Home/463.png') no-repeat;
      background-size: cover;
      position: relative;
      .top {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        margin: 0 auto;

        .logo {
          height: 30px;
          margin: 25px 15px;
        }
        .left {
          width: 1000px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          display: none;
          li {
            color: #fff;
            list-style: none;
            display: block;
            cursor: pointer;
            font-size: 16px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            .homeIcon {
              margin-right: 10px;
            }
          }
          .first {
            border-left: 1px solid #fff;
            padding-left: 21px;
            display: flex;
            align-items: center;
          }
        }
        .right {
          margin-left: 145px;
          width: 280px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          display: none;
          /deep/ .el-input {
            width: 206px;
            height: 36px;
            .el-input__inner {
              background-color: rgba(255, 255, 255, 0.3);
              -webkit-backdrop-filter: blur(2px);
              backdrop-filter: blur(2px);
              border-radius: 25px;
              border: 0;
              color: #fff;
            }
            .el-icon-search {
              color: #fff;
            }
          }
        }
        .rightPe {
          display: block;
          width: 21px;
          height: 16.5px;
          position: absolute;
          right: 25px;
          top: 20.5px;
        }
      }
      #left_arrow {
        position: absolute;
        top: 50%;
        left: 20px;
        margin-top: 0px;
        width: 30px;
        height: 30px;
      }
      #right_arrow {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: rotate(-180deg);
        margin-top: 0px;
        width: 30px;
        height: 30px;
      }
      .currentBox {
        width: 140px;
        position: absolute;
        right: 0px;
        bottom: 15px;
        display: flex;
        margin-top: -16px;
        li {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #fff;
          margin-left: 20px;
          list-style: none;
        }
        .active {
          width: 15px;
          border-radius: 8px;
          background-color: #4c97fc;
        }
      }
    }
    .containerSS {
      // display: none;
      padding: 0px 0px;
      .layer1 {
        width: 100%;
        padding: 0px 0px;
        .title {
          font-size: 15px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: center;
          color: #333333;
        }
        .introduce {
          font-size: 12px;
          font-family: Didot, Didot-Regular;
          font-weight: 400;
          text-align: center;
          color: #999999;
        }
        .content {
          width: 100%;
          margin-top: 60px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 0 auto;
          .top {
            width: 100%;
            height: 114.5px;
          }
          li {
            list-style: none;
            width: 118.5px;
            height: 114px;
            position: relative;
            .slogan {
              display: none;
              width: 254px;
              height: 66px;
              line-height: 66px;
              background: rgba(255, 255, 255, 0.3);
              border-bottom-left-radius: 5px;
              -webkit-backdrop-filter: blur(6px);
              backdrop-filter: blur(6px);
              position: absolute;
              color: #fff;
              right: 0;
              text-align: center;
              font-size: 30px;
              font-family: Microsoft YaHei, Microsoft YaHei-Bold;
              font-weight: 700;
              color: #ffffff;
            }
            &:nth-child(1) {
              margin-bottom: 10px;
              background: url('../../assets/PE/Home/285.png');
              background-size: cover;
            }
            &:nth-child(2) {
              background: url('../../assets/PE/Home/289.png');
              background-size: cover;
            }
            &:nth-child(3) {
              background: url('../../assets/PE/Home/288.png');
              background-size: cover;
            }
            &:nth-child(4) {
              background: url('../../assets/PE/Home/287.png');
              background-size: cover;
            }
            &:nth-child(2),
            &:nth-child(3) {
              margin-right: 0px;
            }
          }
        }
        .move {
          width: 190px;
          height: 31.5px;
          line-height: 31.5px;
          background: #3c91e9;
          border-radius: 5px;
          box-shadow: 0px 3px 6px 0px rgba(60, 145, 233, 0.38);
          margin: 0 auto;
          margin-top: 15px;
          margin-bottom: 20px;
          font-size: 12px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
      }
      .layer2 {
        height: 242px;
        background: url('../../assets/Home/feiji.png');
        background-size: cover;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        .titles {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 35px;
          line-height: 35px;
          h2 {
            display: block;
            width: 100%;
            font-size: 13px;
            font-family: Microsoft YaHei, Microsoft YaHei-Bold;
            font-weight: 700;
            text-align: left;
            color: #333333;
            display: flex;
            margin-left: 25px;
            margin-top: 0;
            margin-bottom: 0;
            align-items: center;
          }
          span {
            font-size: 10px;
            font-family: Didot, Didot-Regular;
            font-weight: 400;
            text-align: left;
            color: #999999;
            &:nth-child(1) {
              margin-left: 10px;
              margin-right: 10px;
            }
          }
        }
        .top {
          width: 100%;
          display: flex;
          justify-content: space-around;
          margin-top: 20px;
          li {
            height: 100%;
            list-style: none;
            .num {
              font-size: 22.5px;
              font-family: DIN, DIN-Regular;
              font-weight: 400;
              text-align: center;
              color: #3c91e9;
              line-height: 0px;
            }
            .introduce {
              font-size: 10px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              text-align: center;
              color: #333333;
              line-height: 30px;
            }
          }
        }
        .el-divider {
          margin-top: 0px;
          margin-bottom: 0px;
          .el-divider__text {
            background-color: rgba(255, 255, 255, 0);
          }
          img {
            margin: 0 12px;
          }
        }
        .bottom {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          li {
            width: 25%;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            .imgBox {
              margin: 0 auto;
              height: 85px;
              display: none;
              img {
                display: block;
                margin: 0 auto;
              }
            }
            .imgBoxPe {
              margin: 0 auto;
              margin-top: 10px;
              img {
                display: block;
                margin: 0 auto;
              }
            }

            .title {
              margin-top: 5px;
              margin-bottom: 5px;
              width: 100%;
              display: block;
              font-size: 12px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              text-align: left;
              color: #333333;
              text-align: center;
            }
            .introduce {
              width: 235px;
              margin: 0 auto;
              display: block;
              font-size: 10px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              text-align: center;
              color: #bcbfc2;
              line-height: 15px;
              text-align: center;
              span {
                color: #3c91e9;
              }
            }
          }
        }
      }
      .layer3 {
        width: 100%;
        height: 156.5px;
        margin-top: 20px;
        background: url('../../assets/PE/Home/464.png');
        background-size: cover;
        padding-top: 0px;
        .titles {
          display: none;
          font-size: 40px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: left;
          color: #ffffff;
          height: 52px;
          line-height: 52px;
          text-align: center;
        }
        .introduce {
          display: none;

          font-size: 61px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: left;
          color: #ffffff;
          margin-top: 90px;
          text-align: center;
        }
      }
    }
  }
}
</style>
<style lang="less">
.genduomenu {
  width: 223px;
  background-color: #3333336a !important;
  backdrop-filter: blur(8px);
  // border-radius: 40px;
  position: absolute;
  right: 0;
  top: 0;
  .PeSearch {
    width: 188px;
    height: 30px;
    border-radius: 40px;
    margin-left: 20px;
    margin-right: 15px;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #fff;
    .el-input__inner {
      background-color: rgba(255, 255, 255, 0.226) !important;
      color: #fff;
      border-radius: 40px;
    }
  }
  .peMenu {
    width: 100%;
    li {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #fff;
      font-size: 12px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      padding: 0 15px 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
    }
  }
}
</style>
